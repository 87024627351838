<template>
  <CardToolbar
    v-loading="loading"
    class="crawled-artists"
    :title="cardTitle()"
    :show-toolbar-buttons="true"
    :fontStyle="fontStyle"
    :margin="true"
    :padding="false"
  >
    <template v-slot:toolbar>
      <el-checkbox
        style="margin-right: 1rem"
        :disabled="artistsList.length === 0"
        v-model="allSelected"
        @change="selectAllClicked"
        label="Select all on page"
        size="large"
      ></el-checkbox>
      <CardHeaderButtonAI
        data-bs-toggle="modal"
        data-bs-target="#ai_artist_filter_modal"
        >Filter
      </CardHeaderButtonAI>
      <CardHeaderButtonAI
        :disabled="!selectedArtists.length"
        @buttonClicked="addBatchToCRM"
        >+CRM
      </CardHeaderButtonAI>
      <CardHeaderButtonAI solid :disabled="!selectedArtists.length"
        >Update
      </CardHeaderButtonAI>
      <CardHeaderButtonAI
        solid
        @click="routeToSandboxGenrePage"
        v-if="showGenreProfileButton"
      >
        Genre Profile
      </CardHeaderButtonAI>
    </template>
    <template v-slot:body>
      <el-table
        @sort-change="sortResults"
        style="width: 100%"
        row-key="id"
        :fit="true"
        table-layout="fixed"
        stripe
        header-cell-class-name="table-header-text"
        :data="artistsList"
        :row-class-name="tableRowClassName"
        :cell-class-name="tableCellClassName"
      >
        <el-table-column
          sortable="custom"
          label="Name"
          prop="name"
          fixed
          width="200"
        >
          <template #default="scope">
            {{ scope.row.name ? scope.row.name : "" }}
            <span
              :class="[
                'svg-icon svg-icon-checkmark',
                scope.row.isExistInHubspot ? 'svg-icon-primary' : '',
              ]"
            >
              <inline-svg src="/media/icons/duotune/general/gen026.svg" />
            </span>
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          label="Monthly Listeners"
          prop="spotifyListenersTotal"
          align="right"
          width="200"
        >
          <template #default="scope">
            {{ formatNumberIntoHumanizeForm(scope.row.spotifyListenersTotal) }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          label="Growth 24 months"
          prop="listenersGrowth24months"
          align="right"
          width="200"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(scope.row.listenersGrowth24months)
            }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          label="Growth 36 months"
          prop="listenersGrowth36months"
          align="right"
          width="200"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(scope.row.listenersGrowth36months)
            }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          label="Revenues"
          prop="revenue"
          align="right"
          width="200"
        >
          <template #default="scope">
            {{ formatNumberIntoHumanizeForm(scope.row.revenue) }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          label="Fanbase"
          prop="fanbase"
          align="right"
          width="200"
        >
          <template #default="scope">
            {{ formatNumberIntoHumanizeForm(scope.row.fanbase) }}
          </template>
        </el-table-column>
        <!--        <el-table-column-->
        <!--          sortable="custom"-->
        <!--          label="# Playlists"-->
        <!--          prop="playlistTotals"-->
        <!--          align="right"-->
        <!--          width="200"-->
        <!--        >-->
        <!--          <template #default="scope">-->
        <!--            {{ formatNumberIntoHumanizeForm(scope.row.playlistTotals) }}-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column
          sortable="custom"
          label="Playlist Reach"
          prop="playlistReach"
          align="right"
          width="200"
        >
          <template #default="scope">
            {{ formatNumberIntoHumanizeForm(scope.row.playlistReach) }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          label="Average Catalog Age"
          prop="averageCatalogAge"
          align="right"
          width="220"
        >
          <template #default="scope">
            {{ formatNumberIntoHumanizeForm(scope.row.averageCatalogAge) }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          label="Weighted Catalog Age"
          prop="weightedCatalogAge"
          align="right"
          width="230"
        >
          <template #default="scope">
            {{ formatNumberIntoHumanizeForm(scope.row.weightedCatalogAge) }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          label="Weighted Catalog Age From Total"
          prop="weightedCatalogAgeFromTotal"
          align="right"
          width="260"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(
                scope.row.weightedCatalogAgeFromTotal
              )
            }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          label="% Played Songs > 3 Years"
          prop="percentagePlayedSongsGreaterThan3Years"
          align="right"
          width="230"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(
                scope.row.percentagePlayedSongsGreaterThan3Years
              )
            }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          label="Daily Streams from Total (All Songs)"
          prop="dailyStreamsFromTotalByAllSongsOlderThan3Years"
          align="right"
          width="270"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(
                scope.row.dailyStreamsFromTotalByAllSongsOlderThan3Years
              )
            }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          label="Daily Streams from Total (Main Songs)"
          prop="dailyStreamsFromTotalByTypeMainOlderThan3Years"
          align="right"
          width="300"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(
                scope.row.dailyStreamsFromTotalByTypeMainOlderThan3Years
              )
            }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          label="Daily Streams from Total (All Songs - Corrected)"
          prop="dailyStreamsFromTotalCorrectedByAllSongsOlderThan3Years"
          align="right"
          width="350"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(
                scope.row
                  .dailyStreamsFromTotalCorrectedByAllSongsOlderThan3Years
              )
            }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          label="Daily Streams from Total (Main Songs - Corrected)"
          prop="dailyStreamsFromTotalCorrectedByTypeMainOlderThan3Years"
          align="right"
          width="370"
        >
          <template #default="scope">
            {{
              formatNumberIntoHumanizeForm(
                scope.row
                  .dailyStreamsFromTotalCorrectedByTypeMainOlderThan3Years
              )
            }}
          </template>
        </el-table-column>
        <el-table-column label="Email" align="right" width="200">
          <template #default="scope">
            <a
              href="javascript:void(0)"
              v-if="
                (scope.row.googleScrapedEmails &&
                  scope.row.googleScrapedEmails.length > 0) ||
                (scope.row.chartMetricEmails &&
                  scope.row.chartMetricEmails.length > 0)
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                class="icon-style email-icon"
              >
                <path
                  d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm.021 17.824c-3.907 0-6.021-2.438-6.021-5.586 0-3.363 2.381-6.062 6.638-6.062 3.107 0 5.362 2.019 5.362 4.801 0 4.356-5.165 5.506-4.906 3.021-.354.555-.927 1.177-2.026 1.177-1.257 0-2.04-.92-2.04-2.403 0-2.222 1.461-4.1 3.19-4.1.829 0 1.399.438 1.638 1.11l.232-.816h1.169c-.122.416-1.161 4.264-1.161 4.264-.323 1.333.675 1.356 1.562.648 1.665-1.29 1.75-4.664-.499-6.071-2.411-1.445-7.897-.551-7.897 4.347 0 2.806 1.976 4.691 4.914 4.691 1.719 0 2.771-.465 3.648-.974l.588.849c-.856.482-2.231 1.104-4.391 1.104zm-1.172-7.153c-.357.67-.588 1.538-.588 2.212 0 1.805 1.761 1.816 2.626.12.356-.697.586-1.586.586-2.265 0-1.458-1.748-1.717-2.624-.067z"
                />
              </svg>
            </a>
          </template>
        </el-table-column>
        <el-table-column label="Social Sites" align="right" width="300">
          <template #default="scope">
            <a
              :href="
                scope.row.chartMetricContacts.facebook
                  ? scope.row.chartMetricContacts.facebook
                  : scope.row.googleScrapedContacts.facebook
              "
              target="_blank"
              v-if="
                scope.row.chartMetricContacts.facebook ||
                (scope.row.googleScrapedContacts &&
                  scope.row.googleScrapedContacts.facebook)
              "
            >
              <svg
                class="icon-style facebook-icon"
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fill-rule="evenodd"
                clip-rule="evenodd"
              >
                <path
                  d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0 4.5c-3.314 0-6 2.487-6 5.556 0 1.748.872 3.307 2.236 4.326v2.118l2.043-1.121c.545.151 1.123.232 1.721.232 3.314 0 6-2.487 6-5.555 0-3.069-2.686-5.556-6-5.556zm.596 7.481l-1.528-1.629-2.981 1.629 3.279-3.481 1.566 1.63 2.944-1.63-3.28 3.481z"
                />
              </svg>
            </a>
            <a
              :href="
                scope.row.chartMetricContacts.instagram
                  ? scope.row.chartMetricContacts.instagram
                  : scope.row.googleScrapedContacts.instagram
              "
              target="_blank"
              v-if="
                scope.row.chartMetricContacts.instagram ||
                (scope.row.googleScrapedContacts &&
                  scope.row.googleScrapedContacts.instagram)
              "
            >
              <svg
                class="icon-style instagram-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 7.082c1.602 0 1.792.006 2.425.035 1.627.074 2.385.845 2.46 2.459.028.633.034.822.034 2.424s-.006 1.792-.034 2.424c-.075 1.613-.832 2.386-2.46 2.46-.633.028-.822.035-2.425.035-1.602 0-1.792-.006-2.424-.035-1.63-.075-2.385-.849-2.46-2.46-.028-.632-.035-.822-.035-2.424s.007-1.792.035-2.424c.074-1.615.832-2.386 2.46-2.46.632-.029.822-.034 2.424-.034zm0-1.082c-1.63 0-1.833.007-2.474.037-2.18.1-3.39 1.309-3.49 3.489-.029.641-.036.845-.036 2.474 0 1.63.007 1.834.036 2.474.1 2.179 1.31 3.39 3.49 3.49.641.029.844.036 2.474.036 1.63 0 1.834-.007 2.475-.036 2.176-.1 3.391-1.309 3.489-3.49.029-.64.036-.844.036-2.474 0-1.629-.007-1.833-.036-2.474-.098-2.177-1.309-3.39-3.489-3.489-.641-.03-.845-.037-2.475-.037zm0 2.919c-1.701 0-3.081 1.379-3.081 3.081s1.38 3.081 3.081 3.081 3.081-1.379 3.081-3.081c0-1.701-1.38-3.081-3.081-3.081zm0 5.081c-1.105 0-2-.895-2-2 0-1.104.895-2 2-2 1.104 0 2.001.895 2.001 2s-.897 2-2.001 2zm3.202-5.922c-.397 0-.72.322-.72.72 0 .397.322.72.72.72.398 0 .721-.322.721-.72 0-.398-.322-.72-.721-.72z"
                />
              </svg>
            </a>
            <a
              :href="
                scope.row.chartMetricContacts.soundcloud
                  ? scope.row.chartMetricContacts.soundcloud
                  : scope.row.googleScrapedContacts.soundcloud
              "
              target="_blank"
              v-if="
                scope.row.chartMetricContacts.soundcloud ||
                (scope.row.googleScrapedContacts &&
                  scope.row.googleScrapedContacts.soundcloud)
              "
            >
              <svg
                class="icon-style soundcloud-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.709 15h-.541v-4.4c.167-.126.347-.234.541-.309v4.709zm1.625 0h.543v-5.031c-.125.145-.24.299-.337.468l-.206-.101v4.664zm-1.082 0h.541v-4.826c-.274-.043-.337-.027-.541-.005v4.831zm-2.168 0h.543v-3.829c-.17.25-.302.529-.375.836l-.168-.099v3.092zm-1.625-2.863c-.329.298-.541.738-.541 1.236 0 .499.212.938.541 1.238v-2.474zm10.227-.366c-.11-1.548-1.302-2.771-2.772-2.771-.554 0-1.064.179-1.496.479v5.521h4.924c.87 0 1.576-.76 1.576-1.695 0-1.218-1.174-2.056-2.232-1.534zm-9.143-.016c-.163-.014-.285-.017-.541.066v3.103c.241.078.345.076.541.076v-3.245z"
                />
              </svg>
            </a>
            <a
              :href="
                scope.row.chartMetricContacts.twitter
                  ? scope.row.chartMetricContacts.twitter
                  : scope.row.googleScrapedContacts.twitter
              "
              target="_blank"
              v-if="
                scope.row.chartMetricContacts.twitter ||
                (scope.row.googleScrapedContacts &&
                  scope.row.googleScrapedContacts.twitter)
              "
            >
              <svg
                class="icon-style twitter-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.5 8.778c-.441.196-.916.328-1.414.388.509-.305.898-.787 1.083-1.362-.476.282-1.003.487-1.564.597-.448-.479-1.089-.778-1.796-.778-1.59 0-2.758 1.483-2.399 3.023-2.045-.103-3.86-1.083-5.074-2.572-.645 1.106-.334 2.554.762 3.287-.403-.013-.782-.124-1.114-.308-.027 1.14.791 2.207 1.975 2.445-.346.094-.726.116-1.112.042.313.978 1.224 1.689 2.3 1.709-1.037.812-2.34 1.175-3.647 1.021 1.09.699 2.383 1.106 3.773 1.106 4.572 0 7.154-3.861 6.998-7.324.482-.346.899-.78 1.229-1.274z"
                />
              </svg>
            </a>
            <a
              :href="
                scope.row.chartMetricContacts.youtube
                  ? scope.row.chartMetricContacts.youtube
                  : scope.row.googleScrapedContacts.youtube
              "
              target="_blank"
              v-if="
                scope.row.chartMetricContacts.youtube ||
                (scope.row.googleScrapedContacts &&
                  scope.row.googleScrapedContacts.youtube)
              "
            >
              <svg
                class="icon-style youtube-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M16.23 7.102c-2.002-.136-6.462-.135-8.461 0-2.165.148-2.419 1.456-2.436 4.898.017 3.436.27 4.75 2.437 4.898 1.999.135 6.459.136 8.461 0 2.165-.148 2.42-1.457 2.437-4.898-.018-3.436-.271-4.75-2.438-4.898zm-6.23 7.12v-4.444l4.778 2.218-4.778 2.226zm2-12.222c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12z"
                />
              </svg>
            </a>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          fixed="right"
          width="100"
          label="Action"
        >
          <template #default="scope">
            <ArtistMenuComponent
              v-if="scope.row.name"
              :artist="{
                name: scope.row.name,
                uuid: scope.row.uuid,
                spotifyID: scope.row.spotifyID,
              }"
              :buttons="[
                'analytics',
                'discover',
                'crawl',
                scope.row && 'artistDetails',
                'aiSandbox',
                'artistNewDesign',
              ]"
              :showFollowButton="false"
            ></ArtistMenuComponent>
          </template>
        </el-table-column>
        <el-table-column
          :key="checkBoxKey"
          fixed="right"
          width="80"
          label="Select"
          align="right"
        >
          <template #default="scope">
            <el-checkbox
              :model-value="selectedArtists.includes(scope.row.spotifyID)"
              :disabled="
                !selectedArtists.includes(scope.row.spotifyID) &&
                selectedArtists.length > 49
              "
              @change="checkBoxValueChanged($event, scope.row.spotifyID)"
              label=""
              size="large"
            ></el-checkbox>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="mt-5"
        v-model:currentPage="localPagination.offset"
        v-model:page-size="localPagination.limit"
        :page-sizes="[5, 10, 20, 50]"
        :small="true"
        layout="total, sizes, prev, pager, next"
        :total="localPagination.total"
        @size-change="paginationChanged"
        @current-change="paginationChanged"
      >
      </el-pagination>
    </template>
  </CardToolbar>
  <div
    class="modal fade"
    id="ai_artist_filter_modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-850px">
      <div class="modal-content">
        <div
          class="modal-header bg-light-grey"
          id="kt_modal_create_api_key_header"
        >
          <h2 class="card-heading">Genre Filter</h2>
        </div>
        <div class="modal-body bg-light-grey scroll-y py-0">
          <div class="card mb-7">
            <div class="card-body">
              <div class="average-catalogue-age">
                <h5 class="heading">Average Catalogue Age</h5>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="me-4">
                    <el-input-number
                      v-model="averageCatalogNum1"
                      size="small"
                      @change="
                        averageCatalogueAge = [
                          Number(averageCatalogNum1),
                          Number(averageCatalogNum2),
                        ]
                      "
                      :min="minAverageCatalogueAge"
                      :max="maxAverageCatalogueAge"
                    />
                  </div>
                  <div class="w-100">
                    <el-slider
                      id="average_catalog_slider"
                      v-model="averageCatalogueAge"
                      range
                      :min="minAverageCatalogueAge"
                      :max="maxAverageCatalogueAge"
                      @change="changeValue()"
                    />
                  </div>
                  <div class="ms-4">
                    <el-input-number
                      v-model="averageCatalogNum2"
                      size="small"
                      @change="
                        averageCatalogueAge = [
                          Number(averageCatalogNum1),
                          Number(averageCatalogNum2),
                        ]
                      "
                      :min="minAverageCatalogueAge"
                      :max="maxAverageCatalogueAge"
                    />
                  </div>
                </div>
              </div>
              <div class="months-growth mt-10">
                <h4 class="heading">36M Growth</h4>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="me-4">
                    <el-input-number
                      v-model="threeYearsGrowthNum1"
                      size="small"
                      @change="
                        threeYearsGrowth = [
                          Number(threeYearsGrowthNum1),
                          Number(threeYearsGrowthNum2),
                        ]
                      "
                      :min="minThreeYearsGrowth"
                      :max="maxThreeYearsGrowth"
                    />
                  </div>
                  <div class="w-100">
                    <el-slider
                      v-model="threeYearsGrowth"
                      range
                      :min="minThreeYearsGrowth"
                      :max="maxThreeYearsGrowth"
                      id="three_years_growth_slider"
                      @change="changeValue()"
                    />
                  </div>
                  <div class="ms-4">
                    <el-input-number
                      v-model="threeYearsGrowthNum2"
                      size="small"
                      @change="
                        threeYearsGrowth = [
                          Number(threeYearsGrowthNum1),
                          Number(threeYearsGrowthNum2),
                        ]
                      "
                      :min="minThreeYearsGrowth"
                      :max="maxThreeYearsGrowth"
                    />
                  </div>
                </div>
              </div>
              <div class="yearly-revenue mt-10">
                <h4 class="heading">Yearly Revenue</h4>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="me-4">
                    <el-input-number
                      v-model="yearlyRevenueNum1"
                      size="small"
                      @change="
                        yearlyRevenue = [
                          Number(yearlyRevenueNum1),
                          Number(yearlyRevenueNum2),
                        ]
                      "
                      :min="minYearlyRevenue"
                      :max="maxYearlyRevenue"
                    />
                  </div>
                  <div class="w-100">
                    <el-slider
                      v-model="yearlyRevenue"
                      range
                      :min="minYearlyRevenue"
                      :max="maxYearlyRevenue"
                      :step="10"
                      @change="changeValue()"
                    />
                  </div>
                  <div class="ms-4">
                    <el-input-number
                      v-model="yearlyRevenueNum2"
                      size="small"
                      @change="
                        yearlyRevenue = [
                          Number(yearlyRevenueNum1),
                          Number(yearlyRevenueNum2),
                        ]
                      "
                      :min="minYearlyRevenue"
                      :max="maxYearlyRevenue"
                    />
                  </div>
                </div>
              </div>
              <div class="social mt-15">
                <h4 class="heading">
                  Only show results with
                  {{ socialMediaSwitch ? "social media data" : "" }}
                  {{ socialMediaSwitch && emailSwitch ? " and " : "" }}
                  {{ emailSwitch ? "email" : "" }}
                </h4>
                <div class="social-media-switch ms-2 mt-4">
                  <el-switch v-model="socialMediaSwitch" size="large" />
                  <span
                    class="switch-text ms-4"
                    @click="socialMediaSwitch = !socialMediaSwitch"
                    >Social Media Data</span
                  >
                </div>
                <div class="email-switch ms-2 mt-4">
                  <el-switch v-model="emailSwitch" size="large" />
                  <span
                    class="switch-text ms-4"
                    @click="emailSwitch = !emailSwitch"
                    >Email</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer flex-center bg-light-grey">
          <button
            type="reset"
            class="btn me-3 btn-primary"
            data-bs-dismiss="modal"
            @click="filterArtistsArray"
          >
            Apply Filter
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardToolbar from "../../common/components/CardToolbar";
import { ref, watch } from "vue";
import CardHeaderButtonAI from "@/aiBetaBench/components/CardHeaderButtonAI";
import { getMongoAggregatedArtistByGenre } from "@/api/genreResult";
import { paginate, sortArray } from "@/aiBetaBench/helpers";
import ArtistMenuComponent from "../../artists/components/ArtistMenuComponent";
import { ElNotification } from "element-plus";
import { addArtistsToCRM } from "@/api/hubspot";
import {
  getSocialSitesFromChartMetricArray,
  getSocialSitesFromGoogleScrapedContacts,
  formatNumberIntoHumanizeForm,
} from "@/utils/staticHelper";

export default {
  name: "SelectedGenreArtists",
  components: { CardToolbar, ArtistMenuComponent, CardHeaderButtonAI },
  props: {
    genre: {
      type: String,
      default: null,
    },
    margin: {
      type: Boolean,
      default: false,
    },
    fontStyle: {
      type: Boolean,
      default: false,
    },
    showGenreProfileButton: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const loading = ref(false);
    const artistsList = ref([]);
    const allSelected = ref(false);
    const artistsListWithoutPagination = ref([]);
    const selectedArtists = ref([]);
    const checkBoxKey = ref(0);
    const localPagination = ref({
      offset: 1,
      limit: 10,
      total: 0,
    });
    const sortBy = ref(null);
    const sortDir = ref(null);
    const socialMediaSwitch = ref(true);
    const emailSwitch = ref(false);
    const averageCatalogueAge = ref([1500, 15000]);
    const averageCatalogNum1 = ref(1500);
    const averageCatalogNum2 = ref(15000);
    const minAverageCatalogueAge = ref(1500);
    const maxAverageCatalogueAge = ref(15000);

    const threeYearsGrowth = ref([-5, 100]);
    const threeYearsGrowthNum1 = ref(-5);
    const threeYearsGrowthNum2 = ref(100);
    const minThreeYearsGrowth = ref(-5);
    const maxThreeYearsGrowth = ref(100);

    const yearlyRevenue = ref([1500, 1000000]);
    const yearlyRevenueNum1 = ref(1500);
    const yearlyRevenueNum2 = ref(1000000);
    const minYearlyRevenue = ref(1500);
    const maxYearlyRevenue = ref(1000000);

    const isFilterActive = ref(false);
    const filteredArtists = ref([]);

    const cardTitle = () => {
      if (props.genre === null) {
        return "Artists";
      } else {
        return "Artists listed in " + props.genre;
      }
    };
    const changeValue = () => {
      averageCatalogNum1.value = averageCatalogueAge.value[0];
      averageCatalogNum2.value = averageCatalogueAge.value[1];
      threeYearsGrowthNum1.value = threeYearsGrowth.value[0];
      threeYearsGrowthNum2.value = threeYearsGrowth.value[1];
      yearlyRevenueNum1.value = yearlyRevenue.value[0];
      yearlyRevenueNum2.value = yearlyRevenue.value[1];
    };
    const getMaxValues = (array, key) => {
      if (array) {
        return Math.max(
          ...array.map((o) => (o[key] ? Number(o[key]) : Number(0)))
        );
      }
      return 100;
    };
    const getMinValues = (array, key) => {
      if (array) {
        return Math.min(
          ...array.map((o) => (o[key] ? Number(o[key]) : Number(0)))
        );
      }
      return 0;
    };
    const getArtists = async (genre) => {
      try {
        loading.value = true;
        let { data } = await getMongoAggregatedArtistByGenre(genre);
        artistsList.value = [];
        artistsListWithoutPagination.value = [];
        isFilterActive.value = false;
        for (const artist of data) {
          const payload = {
            name: artist.name
              ? artist.name.charAt(0).toUpperCase() + artist.name.slice(1)
              : "",
            spotifyID: artist.spotifyID ? artist.spotifyID : null,
            uuid: artist.soundchartsUUID ? artist.soundchartsUUID : null,
            averageCatalogAge: artist.averageCatalogAge
              ? artist.averageCatalogAge
              : 0,
            weightedCatalogAge: artist.weightedCatalogAge
              ? artist.weightedCatalogAge
              : 0,
            weightedCatalogAgeFromTotal: artist.weightedCatalogAgeFromTotal
              ? artist.weightedCatalogAgeFromTotal
              : 0,
            percentagePlayedSongsGreaterThan3Years:
              artist.percentagePlayedSongsGreaterThan3Years
                ? artist.percentagePlayedSongsGreaterThan3Years
                : 0,
            dailyStreamsFromTotalByAllSongsOlderThan3Years:
              artist.dailyStreamsFromTotalByAllSongsOlderThan3Years
                ? artist.dailyStreamsFromTotalByAllSongsOlderThan3Years
                : 0,
            dailyStreamsFromTotalByTypeMainOlderThan3Years:
              artist.dailyStreamsFromTotalByTypeMainOlderThan3Years
                ? artist.dailyStreamsFromTotalByTypeMainOlderThan3Years
                : 0,
            dailyStreamsFromTotalCorrectedByAllSongsOlderThan3Years:
              artist.dailyStreamsFromTotalCorrectedByAllSongsOlderThan3Years
                ? artist.dailyStreamsFromTotalCorrectedByAllSongsOlderThan3Years
                : 0,
            dailyStreamsFromTotalCorrectedByTypeMainOlderThan3Years:
              artist.dailyStreamsFromTotalCorrectedByTypeMainOlderThan3Years
                ? artist.dailyStreamsFromTotalCorrectedByTypeMainOlderThan3Years
                : 0,
            spotifyListenersTotal: artist.chartmetricSpotifyListenersTotal
              ? artist.chartmetricSpotifyListenersTotal
              : 0,
            listenersGrowth24months:
              artist.chartmetricSpotifyListenersGrowth &&
              artist.chartmetricSpotifyListenersGrowth["24Months"]
                ? artist.chartmetricSpotifyListenersGrowth["24Months"]
                : 0,
            listenersGrowth36months:
              artist.chartmetricSpotifyListenersGrowth &&
              artist.chartmetricSpotifyListenersGrowth["36Months"]
                ? artist.chartmetricSpotifyListenersGrowth["36Months"]
                : 0,
            // playlistTotals:
            //   artist.playlistMetrics && artist.playlistMetrics.total
            //     ? artist.playlistMetrics.total
            //     : 0,
            revenue: artist.chartmetricRevenue ? artist.chartmetricRevenue : 0,
            fanbase: artist.chartmetricFanbase ? artist.chartmetricFanbase : 0,
            playlistReach: artist.playlistReach ? artist.playlistReach : 0,
            googleScrapedContacts: artist.googleScrapedContacts
              ? getSocialSitesFromGoogleScrapedContacts(
                  artist.googleScrapedContacts
                )
              : {},
            googleScrapedEmails:
              artist.googleScrapedContacts &&
              artist.googleScrapedContacts.emails
                ? artist.googleScrapedContacts.emails
                : [],
            chartMetricEmails:
              artist.chartmetricContacts && artist.chartmetricContacts.emails
                ? artist.chartmetricContacts.emails
                : [],
            chartMetricContacts: getSocialSitesFromChartMetricArray(
              artist.chartmetricContacts && artist.chartmetricContacts.contacts
                ? artist.chartmetricContacts.contacts
                : {}
            ),
            isExistInHubspot: artist.hubspotID ? artist.hubspotID : "",
          };
          artistsListWithoutPagination.value.push(payload);
        }
        const maxCatalogueValue = getMaxValues(
          artistsListWithoutPagination.value,
          "averageCatalogAge"
        );
        maxAverageCatalogueAge.value = maxCatalogueValue;
        averageCatalogNum2.value = maxCatalogueValue;
        const minCatalogueValue = getMinValues(
          artistsListWithoutPagination.value,
          "averageCatalogAge"
        );
        minAverageCatalogueAge.value =
          minCatalogueValue > 1500 ? 1500 : minCatalogueValue;
        averageCatalogNum1.value =
          maxCatalogueValue > 1500 ? 1500 : minCatalogueValue;
        averageCatalogueAge.value = [
          averageCatalogNum1.value,
          averageCatalogNum2.value,
        ];

        const minGrowth = getMinValues(
          artistsListWithoutPagination.value,
          "listenersGrowth36months"
        );
        minThreeYearsGrowth.value = minGrowth < -5 ? minGrowth : -5;
        const maxGrowth = getMaxValues(
          artistsListWithoutPagination.value,
          "listenersGrowth36months"
        );
        maxThreeYearsGrowth.value = maxGrowth > 100 ? maxGrowth : 1000;
        const minRevenue = getMinValues(
          artistsListWithoutPagination.value,
          "revenue"
        );
        minYearlyRevenue.value = minRevenue < 1500 ? minRevenue : 1500;
        const maxRevenue = getMaxValues(
          artistsListWithoutPagination.value,
          "revenue"
        );
        maxYearlyRevenue.value = maxRevenue > 1000000 ? maxRevenue : 1000000;
        localPagination.value.total = data.length;
        localPagination.value.offset = 1;
        localPagination.value.limit = 10;
        data = [];
        paginationChanged();
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };
    const sortAllDataIfRequired = (array) => {
      if (sortBy.value && sortDir.value) {
        return sortArray(sortBy.value, sortDir.value, array);
      } else {
        return array;
      }
    };
    const sortResults = ({ prop, order }) => {
      sortBy.value = prop;
      sortDir.value = order;
      if (isFilterActive.value === true) {
        filteredArtists.value = sortAllDataIfRequired(
          JSON.parse(JSON.stringify(filteredArtists.value))
        );
      } else {
        artistsListWithoutPagination.value = sortAllDataIfRequired(
          JSON.parse(JSON.stringify(artistsListWithoutPagination.value))
        );
      }
      paginationChanged();
    };
    const paginationChanged = () => {
      allSelected.value = false;
      if (isFilterActive.value === true) {
        artistsList.value = filteredArtists.value;
      } else {
        artistsList.value = artistsListWithoutPagination.value;
      }
      artistsList.value = paginate(
        artistsList.value,
        localPagination.value.limit,
        localPagination.value.offset
      );
    };
    const filterArtistsArray = () => {
      loading.value = true;
      filteredArtists.value = artistsListWithoutPagination.value.filter(
        function (artist) {
          return (
            artist["averageCatalogAge"] >= averageCatalogueAge.value[0] &&
            artist["averageCatalogAge"] <= averageCatalogueAge.value[1] &&
            artist["listenersGrowth36months"] >= threeYearsGrowth.value[0] &&
            artist["listenersGrowth36months"] <= threeYearsGrowth.value[1] &&
            artist["revenue"] >= yearlyRevenue.value[0] &&
            artist["revenue"] <= yearlyRevenue.value[1] &&
            (socialMediaSwitch.value
              ? Object.keys(artist["chartMetricContacts"]).length > 0 ||
                Object.keys(artist["googleScrapedContacts"]).length > 0
              : artist["googleScrapedContacts"] ||
                artist["chartMetricContacts"]) &&
            (emailSwitch.value
              ? artist["chartMetricEmails"].length > 0 ||
                artist["googleScrapedEmails"].length > 0
              : artist["chartMetricEmails"] || artist["googleScrapedEmails"])
          );
        }
      );
      isFilterActive.value = true;
      localPagination.value.total = filteredArtists.value.length;
      localPagination.value.offset = 1;
      localPagination.value.limit = 10;
      paginationChanged();
      loading.value = false;
    };
    watch(
      () => props.genre,
      async (val) => {
        await getArtists(val);
      }
    );
    const routeToSandboxGenrePage = () => {
      if (props.genre) {
        window.open(
          `${process.env.VUE_APP_SANDBOX_GENRE_URI}/?genre=${props.genre}`,
          "_blank"
        );
      }
    };
    const checkBoxValueChanged = (event, id) => {
      allSelected.value = false;
      if (event && !selectedArtists.value.includes(id)) {
        selectedArtists.value.push(id);
      } else if (!event) {
        const index = selectedArtists.value.indexOf(id);
        if (index > -1) {
          selectedArtists.value.splice(index, 1);
        }
      }
    };
    const selectAllClicked = (value) => {
      if (value) {
        selectedArtists.value = artistsList.value.map(
          (object) => object.spotifyID
        );
      } else {
        selectedArtists.value = [];
      }
    };
    const addBatchToCRM = async () => {
      if (selectedArtists.value.length) {
        loading.value = true;
        try {
          const mappedArtists = artistsList.value
            .filter((g) => selectedArtists.value.includes(g.spotifyID))
            .map((object) => {
              return {
                spotifyID: object.spotifyID,
              };
            });
          addArtistsToCRM(mappedArtists);
          checkBoxKey.value++;
          selectedArtists.value = [];
          allSelected.value = false;
          ElNotification({
            title: "Success",
            message: "Selected artists added to CRM!",
            type: "success",
          });
        } catch (e) {
          ElNotification({
            title: "Error",
            message: "Error adding to CRM, try again later",
            type: "error",
          });
        } finally {
          loading.value = false;
        }
      }
    };
    const tableRowClassName = ({ rowIndex }) => {
      return rowIndex % 2 === 0
        ? "table-data-text"
        : "table-data-text striped-table-row";
    };
    const tableCellClassName = ({ columnIndex }) => {
      if (columnIndex === 0 || columnIndex === 1) {
        return "table-column-ellipses";
      } else {
        return "";
      }
    };
    return {
      loading,
      artistsList,
      selectedArtists,
      checkBoxKey,
      localPagination,
      allSelected,
      socialMediaSwitch,
      emailSwitch,
      averageCatalogueAge,
      averageCatalogNum1,
      averageCatalogNum2,
      threeYearsGrowth,
      threeYearsGrowthNum1,
      threeYearsGrowthNum2,
      yearlyRevenue,
      yearlyRevenueNum1,
      yearlyRevenueNum2,
      maxAverageCatalogueAge,
      minAverageCatalogueAge,
      maxYearlyRevenue,
      minYearlyRevenue,
      maxThreeYearsGrowth,
      minThreeYearsGrowth,
      cardTitle,
      paginationChanged,
      sortAllDataIfRequired,
      sortResults,
      routeToSandboxGenrePage,
      checkBoxValueChanged,
      addBatchToCRM,
      selectAllClicked,
      getSocialSitesFromChartMetricArray,
      tableRowClassName,
      tableCellClassName,
      filterArtistsArray,
      changeValue,
      formatNumberIntoHumanizeForm,
    };
  },
};
</script>
<style lang="scss">
.icon-style {
  margin-right: 4px;
  fill: gray;
}

#ai_artist_filter_modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        border-bottom: none !important;

        .card-heading {
          color: #33647f !important;
          font-weight: 600 !important;
        }
      }
    }

    .modal-footer {
      border-top: none !important;
    }
  }

  .switch-text {
    color: #33647f !important;
    font-weight: 600 !important;
    vertical-align: middle;
    cursor: pointer;
  }

  .slider-text {
    color: #33647f !important;
    font-weight: 600 !important;
  }
}
</style>
